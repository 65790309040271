<template>
	<banner-style1 :position="8"></banner-style1>
	<div class="content-warp c w">
		<div class="warp">
			<h1>{{data.name}}</h1>
			<div class="content">
				<img :src="data.img" alt="">
				<div class="html">
					<p class="con-html" v-html="data.content"></p>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
	import {
		getAboutContent
	} from '@/api/about'
	import {
		ref
	} from 'vue'
	let data = ref('')
	getAboutContent({
		type: 1
	}).then(res => {
		console.log(res);
		data.value = res.data
	})
</script>
<style lang="scss" scoped>
	.content-warp {
		text-align: center;
		padding: 80px 0 200px;

		.content {
			display: flex;

			img {
				width: 500px;
				object-fit: contain;
			}

			.html {
				flex: 1;
				padding: 0 30px;
			}
		}

		h1 {
			margin-bottom: 50px;
		}

		h3 {
			font-weight: 400;
			margin-bottom: 20px;
		}
	}
</style>
